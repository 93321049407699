<template>
  <div class="ayuda">
    <headNav :activeNav="'FAQ'"></headNav>
    <div class="banner"></div>

    <div class="bigContent">
      <p style="font-size: 26px; margin: 30px 0 0; font-weight: 600">
        「 PREGUNTAS FRECUENTES 」
      </p>
      <div class="content">
        <ul class="btnList">
          <li
            v-for="item in btnList"
            :key="item.id"
            :class="item.id == changeBtn ? 'is-active' : ''"
          >
            <button @click="changeCont(item.id)">{{ item.label }}</button>
          </li>
        </ul>
        <div style="flex: 1; padding-top: 40px" v-if="changeBtn == 1">
          <el-collapse v-model="activeNames">
            <el-collapse-item :name="1">
              <template slot="title">
                <transition name="slide-fade">
                  <div
                    class="collapseDiv"
                    :class="activeNames.indexOf(1) > -1 ? 'activeTitle' : ''"
                  >
                    <div class="icon">
                      <img src="../assets/image/symbol/1.png" />
                      <p>Requisito indispensable:</p>
                    </div>
                    <img
                      class="iconArrow"
                      v-if="activeNames.indexOf(1) == -1"
                      src="../assets/image/symbol/iconBottom.png"
                    />
                    <img
                      class="iconArrow"
                      v-if="activeNames.indexOf(1) > -1"
                      src="../assets/image/symbol/iconTop.png"
                    />
                  </div>
                </transition>
              </template>
              <div class="collapseCont">Edad: mayor de 18 años</div>
            </el-collapse-item>
            <el-collapse-item :name="2">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(2) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/2.png" />
                    <p>Trámites indispensables:</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(2) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(2) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>1) Tener su C.C.ID en la mano</div>
                <div>
                  2) Tener una cuenta de débito de CUALQUIER banco y que esté a
                  SU nombre
                </div>
                <div>3) Llenar su solicitud en la aplicación</div>
                <div>
                  NOTA:
                  <span style="font-size: 16px"
                    >Recuerde que no pedimos ninguna clase de anticipos y que la
                    única forma de hacer su solicitud es por medio de la
                    aplicación. TODO EL PROCESO ES GRATUITO.</span
                  >
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="flex: 1; padding-top: 40px" v-if="changeBtn == 2">
          <el-collapse v-model="activeNames">
            <el-collapse-item :name="1">
              <template slot="title">
                <transition name="slide-fade">
                  <div
                    class="collapseDiv"
                    :class="activeNames.indexOf(1) > -1 ? 'activeTitle' : ''"
                  >
                    <div class="icon">
                      <img src="../assets/image/symbol/1.png" />
                      <p>¿Cómo puedo solicitar mi primer préstamo?</p>
                    </div>
                    <img
                      class="iconArrow"
                      v-if="activeNames.indexOf(1) == -1"
                      src="../assets/image/symbol/iconBottom.png"
                    />
                    <img
                      class="iconArrow"
                      v-if="activeNames.indexOf(1) > -1"
                      src="../assets/image/symbol/iconTop.png"
                    />
                  </div>
                </transition>
              </template>
              <div class="collapseCont">
                <div>
                  Entre a la App y regístrese con su número telefónico (le
                  recomendamos crear una contraseña a la vez) y complete los
                  datos básicos. Después de la autorización, de clic en el botón
                  de
                </div>
                <div>
                  “RETIRAR”, y el préstamo llegará a su cuenta bancaria.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="2">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(2) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/2.png" />

                    <p>¿Cómo puedo solicitar de nuevo un préstamo?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(2) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(2) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Si le rechazaron su última solicitud, puede volver a solicitar
                  después de 7 días; Una vez que se acredite su pago podrá
                  solicitar un segundo préstamo ¡de inmediato! Y esta vez
                  disfrutará de un monto mayor con un costo menor.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="3">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(3) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/3.png" />
                    <p>¿Cómo tener otro préstamo mientras tengo uno activo?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(3) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(3) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Lamentablemente no puede pedir otro préstamo mientras tiene un
                  activo. Debería liquidar su préstamo activo para poder
                  solicitar otro. Eso sí, una vez que liquide, podrá solicitar
                  otro de forma inmedia.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="4">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(4) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/4.png" />
                    <p>
                      ¿Cuánto tiempo tengo para confirmar mi préstamo en la App?
                    </p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(4) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(4) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Le quedan TRES días para confirmar su préstamo. Le
                  recomendamos dar clic en “RETIRAR” lo antes posible. Cuando se
                  convierta en nuestro cliente Premium, disfrutará de un monto
                  mayor con un costo menor.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="5">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(5) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/5.png" />

                    <p>¿Cuánto tiempo tarda en depositar?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(5) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(5) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Procesaremos su solicitud lo antes posible. Una vez aprobada
                  tu solicitud, el depósito se completará en unos minutos. Sin
                  embargo, debido a la diferencia en el horario laboral de los
                  bancos colombianos, puede haber un retraso en su
                  transferencia. No se preocupe, si tiene circunstancias
                  especiales, puede contactarnos al +576015086273 de 8:00 a
                  17:00 horas de lunes a viernes; Sáb de 8:00 a 14:00.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="6">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(6) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/6.png" />
                    <p>¿Qué puedo hacer si falla el depósito?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(6) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(6) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Estimado cliente, en este caso puede intentar comprobar si hay
                  un error en el préstamo, o hay un problema con la recepción de
                  su cuenta, y si el estado de su cuenta bancaria es normal. Si
                  tiene circunstancias especiales, puede contactarnos al
                  +576015086273 de 8:00 a 17:00 horas de lunes a viernes; Sáb de
                  8:00 a 14:00. Le proporcionaremos la información más detallada
                  y le brindaremos servicios más personalizados.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="7">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(7) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/7.png" />
                    <p>
                      ¿Cómo usan la información que lleno en la solicitud de
                      préstamo?
                    </p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(7) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(7) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Parcecash usa sus respuestas para determinar si es candidato a
                  un préstamo de nuestra App. Toda su información se encuentra
                  segura y protegida. Nunca proporcionaremos su información a
                  terceros sin su permiso. Puede leer nuestro Aviso de
                  Privacidad dentro de la App.
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="flex: 1; padding-top: 40px" v-if="changeBtn == 3">
          <el-collapse v-model="activeNames">
            <el-collapse-item :name="1">
              <template slot="title">
                <transition name="slide-fade">
                  <div
                    class="collapseDiv"
                    :class="activeNames.indexOf(1) > -1 ? 'activeTitle' : ''"
                  >
                    <div class="icon">
                      <img src="../assets/image/symbol/1.png" />
                      <p>Me rechazaron mi préstamo, ¿por qué?</p>
                    </div>
                    <img
                      class="iconArrow"
                      v-if="activeNames.indexOf(1) == -1"
                      src="../assets/image/symbol/iconBottom.png"
                    />
                    <img
                      class="iconArrow"
                      v-if="activeNames.indexOf(1) > -1"
                      src="../assets/image/symbol/iconTop.png"
                    />
                  </div>
                </transition>
              </template>
              <div class="collapseCont">
                Lamentamos que su solicitud haya sido rechazada; esto significa
                que no podrá utilizar nuestros servicios por el momento. ¡No se
                preocupe! En esta ocasión no fue candidato para un préstamo,
                pero esperamos que pronto se una a Parcecash. En cuanto sea
                elegible de nuevo, la App le mandará una notificación a su
                celular para realizar una nueva solicitud.
              </div>
            </el-collapse-item>
            <el-collapse-item :name="2">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(2) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/2.png" />
                    <p>
                      Realicé el pago a tiempo (por adelantado), y me rechazaron
                      mi próximo préstamo, ¿por qué?
                    </p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(2) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(2) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Lamentamos que su solicitud haya sido rechazada. Hay varias
                  razones por las que esto sucede. Para tener una mejor
                  comprensión de lo sucedido, puede contactarnos al
                  +576015086273 de 8:00 a 17:00 horas de lunes a viernes; Sáb de
                  8:00 a 14:00 o al correo servicio@parcecash.co, indicando
                  nombre y telefono registrado para que podamos brindarle un
                  servicio personalizado.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="3">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(3) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/3.png" />
                    <p>
                      Realicé el pago, sin embargo, en la App no ha disminuido
                      la cantidad pendiente de pago, ¿qué hago?
                    </p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(3) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(3) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Lamentamos que se haya encontrado con este problema. Favor de
                  tener más paciencia, porque los pagos en efectivo pueden
                  tardar hasta 24 horas en verse reflejados. Y las
                  transferencias interbancarias pueden tardar hasta 72 horas. Si
                  pasado ese tiempo, no ve su pago reflejado, por favor
                  contáctenos al teléfono +576015086273 en un horario de 8:00 a
                  17:00 horas de lunes a viernes; Sáb de 8:00 a 14:00 o por
                  correo servicio@parcecash.co con copia de su comprobante de
                  pago y con gusto le ayudamos.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="4">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(4) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/4.png" />
                    <p>Accidentalmente pagué en exceso, ¿qué hago?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(4) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(4) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  No se preocupe, el dinero en exceso se depositará
                  automáticamente en el saldo de su cuenta de Parcecash y se
                  puede utilizar para el pago.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="5">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(5) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/5.png" />
                    <p>
                      ¿Qué hago si mi cuenta se bloquea y no puedo seguir
                      solicitando?
                    </p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(5) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(5) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Lamentamos que se haya encontrado con este problema. Favor de
                  verificar su red o vuelva a descargar la App para intentar
                  completar su información.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="6">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(6) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/6.png" />
                    <p>La App me devolvió una interfaz de error, ¿qué hago?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(6) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(6) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  En este caso, le recomendamos que primero compruebe si la red
                  funciona con normalidad o intente actualizar la App a la
                  última versión. Si el problema persiste, puede contactarnos al
                  +576015086273 de 8:00 a 17:00 horas de lunes a viernes; Sáb de
                  8:00 a 14:00 o al correo servicio@parcecash.co y proporcione
                  la siguiente información: su nombre completo, C.C.ID de
                  cliente, descripción de problema y la captura de pantalla que
                  muestra la falla.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="7">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(7) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/7.png" />
                    <p>Quiero cambiar mi cuenta bancaria, ¿qué hago?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(7) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(7) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Si no ha solicitado un préstamo, puede modificar la cuenta
                  bancaria en la página “Yo-Información personal”; si ya ha
                  enviado la solicitud, no necesita cambiar su tarjeta bancaria,
                  puede usar cualquier tarjeta bancaria para realizar el pago;
                  cuando liquide su pedido pendiente, también puede modificar la
                  cuenta bancaria en la página “Yo-Información personal” antes
                  de volver a solicitar un préstamo.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="8">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(8) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/8.png" />
                    <p>Quiero cambiar mi número de celular, ¿qué hago?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(8) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(8) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Si desea cambiar su número de celular cuando está en el paso
                  de registro, puede registrarse con su nuevo número de celular;
                  si le queda un pedido por pagar, necesita liquidarlo primero,
                  y luego proporcione a nuestro Servicio al Cliente sus números
                  de celular antiguo y nuevo y su C.C.ID; Si tiene un caso
                  particular, por favor contáctenos al teléfono +576015086273 en
                  un horario de 8:00 a 17:00 horas de lunes a viernes; Sáb de
                  8:00 a 14:00 o por correo servicio@parcecash.co.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="9">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(9) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/9.png" />
                    <p>¿Cómo puedo aumentar el monto de préstamo?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(9) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(9) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Nuestro sistema evalúa el monto según su crédito. Por favor,
                  mantenga un historial crediticio bueno y constante, lo que le
                  ayudará a aumentar el monto de su préstamo. Sin embargo, estos
                  datos se basan en el puntaje integral del sistema y no se
                  permiten factores humanos. Esperamos su comprensión. Si tiene
                  circunstancias especiales, puede contactarnos al +576015086273
                  de 8:00 a 17:00 horas de lunes a viernes; Sáb de 8:00 a 14:00.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="10">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(10) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/10.png" />
                    <p>¿Qué hago si mi pago no se refleja en la App?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(10) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(10) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>
                  Lamentamos que se haya encontrado con este problema.
                  Normalmente el pago se reflejará después de un día laborable
                  si paga en línea, y dentro de 72 horas si paga en efectivo. Si
                  aún no se refleja su pago, por favor se comunique con nosotros
                  al +576015086273 de 8:00 a 17:00 horas de lunes a viernes; Sáb
                  de 8:00 a 14:00, o nos envíe por correo:
                  servicio@parcecash.co.
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="flex: 1; padding-top: 40px" v-if="changeBtn == 4">
          <el-collapse v-model="activeNames">
            <el-collapse-item :name="1">
              <template slot="title">
                <transition name="slide-fade">
                  <div
                    class="collapseDiv"
                    :class="activeNames.indexOf(1) > -1 ? 'activeTitle' : ''"
                  >
                    <div class="icon">
                      <img src="../assets/image/symbol/1.png" />
                      <p>- ¿Cómo pago mi préstamo en línea?</p>
                    </div>
                    <img
                      class="iconArrow"
                      v-if="activeNames.indexOf(1) == -1"
                      src="../assets/image/symbol/iconBottom.png"
                    />
                    <img
                      class="iconArrow"
                      v-if="activeNames.indexOf(1) > -1"
                      src="../assets/image/symbol/iconTop.png"
                    />
                  </div>
                </transition>
              </template>
              <div class="collapseCont">
                <div>a. Ingresar en Parcecash e iniciar sesión;</div>
                <div>b. Dar clic en el botón "PSE" en la página de pago;</div>
                <div>c. Rellenar el monto de pago y los datos personales;</div>
                <div>
                  d. Dar clic en "Confirmar", y entrar en la página de banco
                  automáticamente para pagar el préstamo.
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item :name="2">
              <template slot="title">
                <div
                  class="collapseDiv"
                  :class="activeNames.indexOf(2) > -1 ? 'activeTitle' : ''"
                >
                  <div class="icon">
                    <img src="../assets/image/symbol/2.png" />
                    <p>- ¿Cómo pago mi préstamo en efectivo?</p>
                  </div>
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(2) == -1"
                    src="../assets/image/symbol/iconBottom.png"
                  />
                  <img
                    class="iconArrow"
                    v-if="activeNames.indexOf(2) > -1"
                    src="../assets/image/symbol/iconTop.png"
                  />
                </div>
              </template>
              <div class="collapseCont">
                <div>a. Ingresar en Parcecash e iniciar sesión;</div>
                <div>
                  b. Dar clic en el botón "Efectivo" en la página de pago;
                </div>
                <div>c. Elegir una tienda más cercana;</div>
                <div>
                  d. Dar clic en el botón "Pagar", y revisar la referencia de
                  pago de la tienda;
                </div>
                <div>
                  e. Mostrar la referencia de pago al dependiente y pagar el
                  préstamo.
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>

    <footPage></footPage>
  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "../components/scss/ayuda.scss";

export default {
  name: "ayuda",
  components: { headNav, footPage },
  data() {
    return {
      activeNames: [],
      changeBtn: 1,
      btnList: [
        {
          label: "Los requisitos de solicitud",
          id: 1,
        },
        {
          label: "La solicitud de préstamo",
          id: 2,
        },
        {
          label: "Los casos excepcionales",
          id: 3,
        },
        {
          label: "Forma de pago",
          id: 4,
        },
      ],
    };
  },
  watch: {
    changeBtn() {
      this.activeNames = [];
    },
  },
  methods: {
    changeCont(id) {
      this.changeBtn = id;
    },
  },
};
</script>

<style scoped lang="scss">
.ayuda {
  /deep/ .el-collapse-item__arrow {
    display: none;
  }
  /deep/ .el-collapse-item__header {
    border-bottom: none;
  }
  .bigContent {
    display: flex;
    flex-direction: column;
    background: #fff;
    z-index: 999;
    margin: 316px auto 0;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
    margin-bottom: 60px;
    min-height: 643px;
  }
  .content {
    flex: 1;
    z-index: 99;
    width: 1200px;
    height: 956px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    padding-right: 30px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 1075px;
      height: 139px;
    }
  }
  .collapseDiv {
    font-size: 20px;
  }
  /deep/.el-collapse-item__content {
    padding-bottom: 0;
  }
}
</style>