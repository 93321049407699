<template>
  <div class="headNav">
    <div class="head">
      <div class="mainContent">
        <img
          @click="getActive('Inicio')"
          class="logo"
          src="@/assets/image/logo.png"
          alt=""
        />
        <ul class="nav">
          <li :class="activeNav == 'Inicio' ? 'active' : ''">
            <a @click="getActive('Inicio')">Inicio</a>
          </li>
          <li :class="activeNav == 'sobre' ? 'active' : ''">
            <a @click="getActive('sobre')">Sobre nosotros</a>
          </li>
          <li :class="activeNav == 'FAQ' ? 'active' : ''">
            <a @click="getActive('FAQ')">FAQ</a>
          </li>
          <li :class="activeNav == 'Aviso' ? 'active' : ''">
            <a @click="getActive('Aviso')">Aviso de privacidad</a>
          </li>
          <li :class="activeNav == 'complaints' ? 'active' : ''">
            <a @click="getActive('complaints')">Quejas y Sugerencias</a>
          </li>
          <!-- <li :class="activeNav== 'loan'? 'active' : ''"><a @click="getActive('loan')">Términos y condiciones</a></li> -->
        </ul>
      </div>
    </div>
  </div>
</template> 

<script>
import "./scss/nav.scss";

export default {
  name: "headNav",
  props: ["activeNav"],
  data() {
    return {
      // active: "Inicio",
    };
  },
  created() {
    console.log(this.activeNav);
    // this.active = this.activeNav;
    // console.log(nav, this.active);
    // if (nav) {
    //   this.active = nav;
    // } else {
    //   localStorage.setItem('activeNav', "Inicio");
    // }
  },
  methods: {
    getActive(val) {
      let url = "";
      switch (val) {
        case "Inicio":
          url = "/home";
          break;
        case "sobre":
          url = "/about";
          break;
        case "Aviso":
          url = "/notice";
          break;
        case "FAQ":
          url = "/help";
          break;
        case "loan":
          url = "/loan";
          break;
        case "complaints":
          url = "/complaints";
          break;
      }
      this.$router.push(url);
    },
  },
};
</script>

<style>
</style>