<template>
  <div>
    <headNav :activeNav="'complaints'"></headNav>
    <!-- <div class="container"> -->
    <div class="banner"></div>
    <div class="card">
      <h1>「 Quejas y Sugerencias 」</h1>
      <div style="margin:30px 0;font-weight:600">
        Por favor llene el formulario o envíenos un correo y nos pondremos en
        contacto con usted a la brevedad.
      </div>
      <div style="margin-bottom:20px"><span>Correo electrónico: </span>servicio@parcecash.co</div>
      <div style="margin-bottom:20px"><span>Título de correo: </span> Número telefónico + Su problema</div>
      <div>
        <span>Contenido de correo: </span>
        <p>1. El tiempo que se ocurrió el problema</p>
        <p>2. Nombre de banco</p>
        <p>3. Número de tarjeta bancaria</p>
        <p>4. Descripción de problema</p>
      </div>
      <div></div>
    </div>
    <!-- </div> -->
    <footPage></footPage>
  </div>
</template>
<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "../components/scss/complaints.scss";
export default {
  name: "complaints",
  components: { headNav, footPage },
};
</script>