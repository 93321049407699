<template>
  <div class="Sobre">
    <headNav :activeNav="'sobre'"></headNav>
    <img src="@/assets/image/2_banner.png" alt="" class="banner_img"/>
    <div class="big_content">
      <div class="left">
        <div class="title" style="font-weight: 900">Perfil de la empresa</div>
        <p>
          <u>Parcecash</u> es una plataforma que ofrece préstamos en línea
          rápidos, accesibles y seguros. Regístrate en segundos, aplica para un
          préstamo y recibe tu efectivo en minutos.Vemos el mundo diferente.
          Estamos motivados por una creencia fundamental en las personas, y
          trabajamos juntos para demostrar su potencial. Asumimos riesgos que
          otros no tomarían para poner más poder en las manos de nuestros
          clientes.
        </p>
      </div>
      <div class="right">
        <img src="@/assets/image/about_img.png" alt="" />
      </div>
    </div>
    <div class="p6">
      <div class="sobreMainContent" style="text-align: center">
        <div class="title">Contáctanos</div>
        <p class="item-title" style="margin-bottom: 50px">
          Si necesita cualquier ayuda, favor de enviarnos un correo o llamar a
          nuestro teléfono de atención al cliente.
        </p>
        <ul>
          <li>
            <img src="../assets/image/dianhua.png" alt="" />
            <div>
              <p style="color: #666666; margin-bottom: 7px">
                Atención al Cliente:
              </p>
              <p style="color: #333333">+57 6015086273</p>
            </div>
          </li>
          <li>
            <img src="../assets/image/whats_app.png" alt="" />
            <div>
              <p style="color: #666666; margin-bottom: 7px">WhatsApp:</p>
              <p style="color: #333333">3208546366 / 3208528639</p>
            </div>
          </li>
          <li>
            <img src="../assets/image/ico_2.png" alt="" />
            <div>
              <p style="color: #666666; margin-bottom: 7px">Email:</p>
              <p style="color: #333333">servicio@parcecash.co</p>
            </div>
          </li>
          <li>
            <img src="../assets/image/tm_ico.png" alt="" />
            <div>
              <p style="color: #666666; margin-bottom: 7px">
                Horario de Atención:
              </p>
              <p style="color: #333333">
                de 8:00 a 17:00 horas de lunes a viernes; Sáb de 8:00 a 14:00
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <footPage></footPage>
  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";

import "../components/scss/sobre.scss";

export default {
  name: "Sobre",
  components: { headNav, footPage },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>
 