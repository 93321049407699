<template>
  <div class="foot">
    <!-- <div class="footLeft">
     <img style="width:auto"  src="../assets/image/logo2.png" alt="">
   </div>
    <href="https://play.google.com/store/apps/details?id=credit.prestamos.personale.cash.deposito.inmediato" -->
    <!-- <a  target="_blank" class="playBtn"><img src="@/assets/image/xz_ico.png" alt=""></a> -->
    <!-- -->
    <div class="column">
      <div class="content">Enlace Rápido</div>
      <a href="/about">
        <div>Sobre Nosotros</div>
      </a>
      <a href="/help">
        <div>Preguntas Frecuentes</div>
      </a>
    </div>
    <div class="column">
      <div class="content">Sobre Parcecash</div>
      <a href="/notice">
        <div>AVISO DE PRIVACIDAD</div>
      </a>
      <a target="blank" href="https://www.parcecash.co/h5/loan.html">
        <div>TÉRMINOS Y CONDICIONES DE PRÉSTAMO</div></a
      >
      <a target="blank" href="https://www.parcecash.co/h5/use.html">
        <div>TÉRMINOS Y CONDICIONES DEL USUARIO</div>
      </a>
    </div>
    <div class="column columnImg">
      <div class="content">Contactos</div>
      <a
        target="blank"
        href="https://www.facebook.com/Parcecash-105178595488086"
      >
        <div>Facebook@Parcecash</div>
      </a>
      <a target="blank" href="https://www.instagram.com/parcecash/">
        <div>Instagram@Parcecash</div>
      </a>
      <div class="columnFlex">
        <img src="../assets/image/facebook.png" alt="" />
        <img src="../assets/image/Instagram_logo.png" alt="" />
      </div>
    </div>
    <div class="column columnImgLast">
      <div class="content">Descargar</div>
      <a target="blank" href="https://prod-co.obs.na-mexico-1.myhuaweicloud.com/parcecash/apk/Parcecash_release_v1.0.49_0203.apk">
        <img src="../assets/image/playBtn.png" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
import "./scss/foot.scss";
export default {
  name: "footPage",
  data() {
    return {};
  },
};
</script>
