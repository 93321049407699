<template>
  <div class="Inicio">
    <div class="banner">
      <div class="mainContent">
        <p style="width: 700px; margin-top: 60px">
          ¡A solicitar un préstamo fácil y seguro en cualquier lugar y momento!
        </p>
        <a target="blank" href="https://prod-co.obs.na-mexico-1.myhuaweicloud.com/parcecash/apk/Parcecash_release_v1.0.49_0203.apk" class="playBtn"
          ><img src="@/assets/image/playBtn.png" alt=""
        /></a>
      </div>
    </div>

    <div class="p3">
      <div class="mainContent"> 
        <div class="title">
          Requisitos para solicitar un préstamo
        </div>
        <div class="content">
          <div class="item">
            <img src="@/assets/image/p3_1.png" alt="" />
            <p class="item-title">Cumplir la mayoría de edad</p>
          </div>
          <div class="item">
            <img src="@/assets/image/p3_2.png" alt="" />
            <p class="item-title">Tener la C.C.ID vigente</p>
          </div>
          <div class="item">
            <img src="@/assets/image/p3_3.png" alt="" />
            <p class="item-title">Contar con una propia tarjeta bancaria</p>
          </div>
          <div class="item">
            <img src="@/assets/image/p3_4.png" alt="" />
            <p class="item-title">
              Tener su propio teléfono celular y correo electrónico
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="p4"> 
      <div class="mainContent">
        <div class="title">
          Cómo obtener un préstamo en Parcecash?
        </div>

        <div class="content">
          <div
            class="item"
            :class="changeNum == 1 ? 'activeItem' : ''"
            @click="changeList(1)"
          >
            <img src="../assets/image/p4_1.png" alt="" />
            <p class="item-title">Descarga la App</p>
            <div class="Titleline" style="margin: 0 0 14px"></div>
            <p class="item-subtitle">Descargar Parcecash en</p>
            <p class="item-subtitle">
              <a target="blank" href="https://prod-co.obs.na-mexico-1.myhuaweicloud.com/parcecash/apk/Parcecash_release_v1.0.49_0203.apk">Google Play</a>
            </p>
          </div>
          <div
            class="item"
            :class="changeNum == 2 ? 'activeItem' : ''"
            @click="changeList(2)"
          >
            <img src="../assets/image/p4_2.png" alt="" />
            <p class="item-title">Llenar un formulario</p>
            <div class="Titleline" style="margin: 0 0 14px"></div>
            <p class="item-subtitle">
              Crear la cuenta con solo 4 pasos sencillos para entregar la
              solicitud
            </p>
          </div>
          <div
            class="item"
            :class="changeNum == 3 ? 'activeItem' : ''"
            @click="changeList(3)"
          >
            <img src="../assets/image/p4_3.png" alt="" />
            <p class="item-title">Esperar un momento</p>
            <div class="Titleline" style="margin: 0 0 14px"></div>
            <p class="item-subtitle">Esperar la aprobación del préstamo</p>
          </div>
          <div
            class="item"
            :class="changeNum == 4 ? 'activeItem' : ''"
            @click="changeList(4)"
          >
            <img src="../assets/image/p4_4.png" alt="" />
            <p class="item-title">Depósito exitoso</p>
            <div class="Titleline" style="margin: 0 0 14px"></div>
            <p class="item-subtitle">
              Depositamos el dinero del préstamo directamente en su cuenta
              bancaria
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="p6" style="background:#f5f5f5">
      <div class="mainContent" style="text-align:center">
        <div class="title">
          <img src="../assets/image/kf_ico.png" alt="" />Contáctanos
        </div>
        <p class="item-title" style="margin-bottom:50px">
          Si necesitas ayuda, envíanos un correo o llamar a nuestro teléfono de
          atención al cliente.
        </p> 
         <ul>
          <li>
            <img src="../assets/image/sj_ico.png" alt="">
            <div>
              <p>Atención al Cliente：</p>
              <p style="color:#333">5544406845</p>
            </div>
          </li>
           <li>
            <img src="../assets/image/ico_2.png" alt="">
            <div>
              <p>Email：</p>
              <p style="color:#333">Rapidayuda.prestamo@gmail.com</p>
            </div>
          </li>
           <li>
            <img src="../assets/image/tm_ico.png" alt="">
            <div>
              <p>Tiempo laboral：</p>
              <p style="color:#333">De lunes a viernes 9:00-18:40</p> 
              <p style="color:#333">De sábado a domingo 9:00-14:40</p>
            </div>
          </li>
        </ul>  
      </div>
    </div> -->
  </div>
</template>

<script>
import "./scss/Inicio.scss";

export default {
  name: "Inicio",
  data() {
    return {
      changeNum: 0,
      sliderValue: 5000,
      currentdate: "",
      addDate: "",
      isActive: 61,
    };
  },
  created() {
    this.getNowFormatDate();
  },
  methods: {
    changeList(index) {
      this.changeNum = index;
    },
    getDays(day) {
      this.isActive = day;
      this.dateAddDays(this.currentdate, day);
    },
    dealNumber(num) {
      if (num != 0 && num) {
        num = num + "";
        let splitList = num.split(".");
        //将整数部分拆解为单个数字的数组倒序，然后拼装为新的数组，每3位数字进行一次匹配
        let intStrList = splitList[0]
          .split("")
          .reverse()
          .join("")
          .match(/(\d{1,3})/g);
        //将匹配后的数组用，拼接，再拆解为单个数字的数组，反转为原先数组形式，拼接为完整数字
        let intStr = intStrList.join(",").split("").reverse().join("");
        return intStr;
      }
      return num;
    },
    dateAddDays(dateStr, dayCount) {
      var tempDate = new Date(dateStr.replace(/-/g, "/")); //把日期字符串转换成日期格式
      var resultDate = new Date((tempDate / 1000 + 86400 * dayCount) * 1000); //增加n天后的日期
      let mounth = (resultDate.getMonth() + 1).toString();
      if (mounth.length == 1) {
        mounth = "0" + mounth;
      }
      let today = resultDate.getDate().toString();
      if (today.length == 1) {
        today = "0" + today;
      }

      var resultDateStr = today + "-" + mounth + "-" + resultDate.getFullYear(); //将日期转化为字符串格式
      this.addDate = resultDateStr;
    },
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      this.currentdate = currentdate;
      this.getDays(90);
    },
  },
};
</script>

<style scoped lang='scss'>
.p4 .item {
  cursor: pointer;
}
.p4 .item:hover {
  box-shadow: 0px 2px 8px 0px rgba(197, 214, 224, 0.8);
}
video {
  width: 1000px !important;
  height: auto !important;
  margin-top: 100px !important;
}
.p4 .item-subtitle {
  line-height: 24px;
  a {
    font-size: 16px;
    color: #224c9e;
    text-decoration: underline;
    font-family: PingFangSC-Semibold, PingFang SC;
  }
}
.playBtn img {
  height: auto !important;
}
</style>
