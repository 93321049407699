<template>
  <div class="Aviso">
    <headNav :activeNav="'Aviso'"></headNav>

    <div class="banner"></div>
    <div class="bigContent">
      <p class="bigTitle">Aviso de Privacidad</p>
      <div class="content">
        <p>Fecha de Actualización: 03/02/2023</p>
        <p class="title">
          1. Política de Privacidad y Tratamiento de datos personales en la
          plataforma PARCECASH y sitios relacionados
        </p>
        <p>
          La presente política tiene como objetivo proteger el derecho
          constitucional del Habeas Data que tienen todas las personas para
          conocer , actualizar y rectificar la información que se haya recogido
          y almacenado en las distintas bases de datos de PARCECASH y en virtud
          del cumplimiento de dicho derecho sólo recolecta y da tratamiento a
          Datos Personales, cuando así haya sido autorizado previamente por su
          titular, implementando las medias de seguridad necesarias para
          proteger datos personales. Tambiés expone los lineamientos que se
          tienen en cuenta para las finalidades del tratamiento de la
          información el área responsable de atender quejasy reclamos y los
          procedimientos para actualizar, rectificar y suprimir la información y
          los respetivos canales para que estos puedan ejercerlos.
        </p>
        <p>
          EmpresaDoowin S.A.S., sociedad comercial identificada en adelante la
          “PARCECASH”, en esa medida la recolección y tratamiento de tus datos
          personales, así como el de nuestros clientes, usuarios y empleados
          está sujeta a las normas vigentes en la República de Colombia sobre
          protección de datos personales.
        </p>
        <p>
          El usuario no puede hacer la solicitud a través de la Website, solo
          vía app por lo que necesita descargarla. Al navegar o usar la
          aplicación para dispositivos móviles, reconoce que ha leído, entendido
          y se obliga a cumplir con la presente Política de Protección de
          Tratamiento de Datos Personales de PARCECASH, con los términos y
          condiciones de la compañía, y cumplir con todas las leyes y
          reglamentos aplicables en la materia, si el usuario no está de acuerdo
          con los términos y condiciones de uso o con cualquier disposición de
          la política de privacidad y tratamiento de datos personales, le
          sugerimos que se abstenga de acceder o usar nuestra aplicación para
          dispositivos móviles, y de usar los productos y servicios que se
          presentan en el mismo.
        </p>
        <p class="title">¿QUÉ DATOS PERSONALES USAMOS</p>
        <p>
          Parcecash recabará de ti (en adelante el “Titular” o “tú”) los
          siguientes datos personales (en lo sucesivo los “datos” o los “datos
          personales”) en tu calidad de uno de nuestros clientes:
        </p>
        <p class="title">- Datos de SMS</p>
        <p>
          Incluye datos de la marca de tiempo, números de teléfono, mensajes
          SMS. (Nuestra aplicación cargará y transferirá sus datos de SMS a
          nuestro servidor
          <span style="color: #0000ff">https://apis.parcecash.co/</span> para
          realizar gestión de riesgos y controles antifraude. Nunca
          compartiremos datos con terceros sin su permiso, y la recopilación de
          SMS se realizará siempre bajo consentimiento. Si desea eliminar sus
          datos privados, puede enviar una solicitud al servicio al cliente a
          este correo electrónico: servicio@parcecash.co, y podemos eliminarlos
          manualmente dentro de 3-5 días hábiles).
        </p>
        <p class="title">- Datos de contacto</p>
        <p>
          Parcecash recuperará información sobre tus listas de contactos
          (nombres y números de contacto, frecuencia de contacto, la marca de
          tiempo). Parcecash nunca se pondrá en contacto con ninguno de tus
          contactos ni proporcionará ninguno de tus datos personales a sus
          contactos a menos que nos lo indiques expresamente por separado.
          Nuestra aplicación cargará la información de Contactos del usuario a
          nuestros servidor:
          <span style="color: #0000ff">https://apis.parcecash.co/</span>. Si
          desea eliminar sus datos privados, puede enviar una solicitud al
          servicio al cliente a este correo electrónico: servicio@parcecash.co,
          y podemos eliminarlos manualmente dentro de 3-5 días hábiles).
        </p>
        <p class="title">- Datos de ubicación</p>
        <p>
          Recopile y supervise información sobre la ubicación de su dispositivo
          para proporcionar la facilidad de servicio de su solicitud de préstamo
          y también proporcione ofertas personalizadas.
        </p>
        <p class="title">- Datos de listado de app instaladas</p>
        <p>
          Recopilar y controlar información relacionada con su capacidad
          informada en la aplicación, y cifrada subirlo a en nuestro servidor
          <span style="color: #0000ff">https://apis.parcecash.co/</span>, a fin
          de identificar yanalizar su comportamiento y riesgo frente a préstamos
          múltiples para evaluar si se puede procesar el préstamo o no ayudando
          a prevenir fraude.
        </p>
        <p>- Cámara</p>
        <p>
          Parcecash te pedirá que subas fotos de tu identificación. Parcecash
          también puede pedirte que subas copias de documentos para acreditar
          tus ingresos.
        </p>
        <p>- Datos de tu teléfono celular o dispositivo móvil</p>
        <p>
          Información del hardware, IMEI, dirección IP, dirección MAC, la
          información de wi-fi, la información de la red móvil, GPS ADID o
          identificación de otro dispositivo, operador móvil.
        </p>
        <p>- Almacenamiento</p>
        <p>
          Le permite cargar documentos e imágenes para la solicitud de préstamo.
        </p>
        <p>- Calendario</p>
        <p>
          Se usa para recordarle la fecha de pago para evitar el impacto del
          pago vencido en su crédito.
        </p>
        <p>- Datos de identificación</p>
        <p>
          Nombre, fecha de nacimiento, captura de imagen, identificación
          oficial, género asignado.
        </p>
        <p>- Datos biométricos</p>
        <p>- Datos laborales y educativos</p>
        <p>- Datos de comunicación con Parcecash</p>
        <p>
          Solicitudes de atención al cliente, respuestas a aclaraciones u otras
          comunicaciones a través de mensajes SMS, correo electrónico o
          grabación de llamadas.
        </p>
        <p>- Datos que proporciones en tu solicitud de préstamo</p>
        <p>
          - Datos transaccionales de las operaciones que realizas con nosotros o
          a través de nosotros, así como de las operaciones que realizas con
          transmisores de dinero, instituciones de fondos de pago electrónico,
          instituciones de financiamiento colectivo, instituciones de crédito y
          otras instituciones financieras o comercios electrónicos.
        </p>
        <p class="title">¿USAMOS DATOS PERSONALES SENSIBLES?</p>
        <p>
          Parcecash no solicita directamente datos raciales o de origen étnico,
          de salud, creencias filosóficas o religiosas, afiliación sindical,
          opiniones políticas, preferencias sexuales (salvo por tu género
          asignado que se requiere para temas de prevención de lavado de
          dinero). Sin embargo, recabamos información relacionadav con tu lista
          de contactos, SMS, registro de SMS e imágenes que incidentalmente
          puede tener datos personales sensibles.
        </p>
        <p class="title">
          NECESIDAD DE LA RECOLECCIÓN Y PROCESAMIENTO DE TUS DATOS
        </p>
        <p>
          Parcecash te informa que los datos personales y personales sensibles
          (recolectados incidentalmente) que te solicita, de conformidad con el
          presente Aviso de Privacidad, son necesarios y suficientes para que
          Parcecash realice un proceso de evaluación de riesgo de crédito y
          probabilidad de pago (scoring crediticio) en base a tu perfil y así
          poder determinar tu capacidad crediticia, lo cual es la base
          fundamental de la determinación de Parcecash para aprobar o rechazar
          el otorgamiento de tu solicitud de crédito y dar inicio a la relación
          jurídica contigo, así como para las demás finalidades primarias y
          secundarias que se señalan en el presente Aviso de Privacidad. Cabe
          resaltar que, la decisión final al respecto no es tomada de forma
          manual por un oficial o empleado de Parcecash, sino que, dicha
          decisión es tomada por los sistemas automatizados de Parcecash de
          conformidad con lo señalado en la sección “Decisiones Automatizadas”
          del presente Aviso de Privacidad.
        </p>
        <p class="title">¿CÓMO OBTENEMOS Y ACTUALIZAMOS TUS DATOS?</p>
        <p>Los datos personales serán obtenidos y actualizados a través de:</p>
        <p>
          1. Ti, cuando bajas la aplicación de Parcecash y/o nos solicitas un
          préstamo;
        </p>
        <p>
          2. Tus otras interacciones con nosotros, incluyendo datos personales
          que voluntariamente compartes con nuestro departamento de atención al
          cliente o con nuestros empleados y agentes;
        </p>
        <p>
          3. Tu teléfono celular o dispositivo móvil cuando otorgas acceso y
          permisos que se describe abajo;
        </p>
        <p>4. Buros de crédito o agencias para prevenir el fraude;</p>
        <p>
          5. Cookies, web beacons y otros medios remotos de recolección
          automática de datos;
        </p>
        <p>
          6. Transmisores de dinero instituciones de fondos de pago electrónico,
          instituciones de financiamiento colectivo, instituciones de crédito y
          otras instituciones financieras y comercios electrónicos, con tu
          consentimiento, cuando sea necesario; y/o
        </p>
        <p>
          7. Terceros y otras fuentes públicas, con tu consentimiento, cuando
          sea necesario.
        </p>
        <p class="title">¿PARA QUÉ USAMOS TUS DATOS?</p>
        <p>
          Las finalidades primarias (en lo sucesivo, las “Finalidades
          Primarias”) que dan origen y son necesarias para el mantenimiento y
          cumplimiento de la relación jurídica entre Parcecash y tú son las
          siguientes:
        </p>
        <p>
          - Verificar tu identidad, prevenir fraudes y cumplir con nuestras
          obligaciones de “conoce a tu cliente” bajo las leyes aplicables de
          prevención de lavado de dinero;
        </p>
        <p>
          - Crear tu perfil crediticio y asignarte una calificación crediticia
          para determinar si eres elegible para usar los productos y servicios
          de Parcecash;
        </p>
        <p>
          - Incorporar los datos personales en instrumentos jurídicos, dar
          cumplimiento a los contratos que celebres con Parcecash, incluyendo,
          pero no limitado a ciertos contratos de usuario de nuestra aplicación
          y plataformas y los contratos de préstamo o crédito entre el Titular y
          Parcecash y administrar y manejar dichos contratos;
        </p>
        <p>
          - Originar, gestionar, dar servicio, mantener y exigir las deudas del
          Titular;
        </p>
        <p>
          - Enviarte información de transacciones o notificaciones de
          transacciones, recordatorios de pago y anuncios a través de servicios
          de telefonía automáticos o mensajes pre-grabados, SMS o email;
        </p>
        <p>
          - Utilizar los datos personales, en cualquier tipo de acto o
          diligencia de cobranza judicial o extrajudicial;
        </p>
        <p class="title">¿CON QUIÉN COMPARTIMOS SUS DATOS?</p>
        <p>
          Los datos personales se transferirán a nivel nacional e internacional,
          a las personas o entidades ubicadas en los siguientes tipos,
          categorías o sectores, con las siguientes finalidades:
        </p>

        <p>
          <span class="title">A. </span>A autoridades nacionales y extranjeras
          competentes, con la finalidad de dar cumplimiento a alguna ley,
          reglamento o disposición legal aplicable, así como con la finalidad de
          prevenir un daño.
        </p>
        <p>
          <span class="title">B. </span>A terceros que adquieran la cartera
          crediticia de Parcecash.
        </p>
        <p>
          <span class="title">C. </span> A terceros interesados en otorgar
          financiamientos o adquirir las acciones o activos de Parcecash o de
          sus accionistas o filiales en Colombia o alrededor del mundo o en
          fusionarse con Parcecash.
        </p>
        <p>
          Asimismo, y de acuerdo con las leyes vigentes, Parcecash deberá, con
          el objeto de cumplir con las Finalidades Primarias descritas en el
          presente, transferir de tiempo en tiempo, información de crédito del
          Titular a las Sociedades de Información Crediticia o Burós de Crédito,
          y en este sentido, al aceptar el presente Aviso de Privacidad (en lo
          sucesivo, este “Aviso”) y al consentir a la celebración del Contrato
          de Usuario, usted confirma que podemos transmitir su información a
          dichas Sociedades de Información Crediticia o Burós de Crédito,
          incluyendo información sobre el incumplimiento a los contratos
          celebrados entre el Titular y Parcecash, entre otros el Contrato del
          Usuario.
        </p>
        <p class="title">TUS DERECHOS ARCO</p>
        <p>
          Tú, por ti mismo o mediante tu representante legal debidamente
          acreditado, puedes ejercer tus derechos de acceso, rectificación,
          cancelación u oposición al tratamiento de tus datos personales (los
          “Derechos ARCO”). Para ello podrás solicitar un formato de ejercicio
          de Derechos ARCO mediante el envío de un correo electrónico a la
          dirección servicio@parcecash.co, en atención de Departamento de
          Protección de Datos Personales/Equipo de Atención al Cliente, al
          número telefónico +57 6015086273 o bien a la dirección de Parcecash
          indicada en este Aviso con el nombre y los datos de contacto del
          Titular.
        </p>
        <p>
          El formato de ejercicio de Derechos ARCO se deberá llenar, firmar y
          presentarse acompañado de la siguiente documentación, a fin de que
          pueda llevarse a cabo la autenticación de usted:
        </p>
        <p>
          1. Tu identificación oficial vigente (Credencial del Instituto
          Nacional Electoral, Pasaporte, Cartilla del servicio Militar Nacional
          o Cédula Profesional).
        </p>
        <p>
          2. En los casos en que el ejercicio de los Derechos ARCO se realice a
          través de tu representante legal, deberá acompañarse la identificación
          oficial del representante, así como el poder correspondiente
          protocolizado que acredite la representación legal conferida por el
          Titular.
        </p>
        <p>
          3. Cuando se quiera ejercer el derecho de rectificación, deberá
          exhibirse la documentación que acredite el cambio solicitado de
          acuerdo a los datos personales a rectificar.
        </p>
        <p>
          Parcecash responderá a dicho formato después de 20 días hábiles
          contados a partir de la fecha en que fue recibido el mismo. Este plazo
          podrá ser ampliado hasta por 20 días hábiles más, cuando el caso lo
          amerite, previa notificación a usted. Parcecash le comunicará la
          resolución adoptada a través de las opciones elegidas por éste en el
          formato de ejercicio de Derechos ARCO.
        </p>
        <p class="title">
          PUEDES NEGARTE O REVOCAR EL CONSENTIMIENTO PARA TRATAR TUS DATOS
        </p>
        <p>
          En caso de que desees revocar tu consentimiento o negarte al
          tratamiento de tus datos personales para las Finalidades Secundarias,
          con posterioridad a la firma y aceptación del presente Aviso, deberás
          enviar un correo electrónico a la dirección indicada en la sección
          anterior al Departamento de Protección de Datos Personales/Equipo de
          Atención al Cliente, servicio@parcecash.co, siguiendo el procedimiento
          para la Negativa al Tratamiento y Revocación del Consentimiento que se
          describe en este Aviso.
        </p>
        <p>
          De acuerdo con lo anterior, el Departamento de Protección de Datos
          Personales/Equipo de Atención al Cliente es el encargado de atender
          todas las solicitudes de ejercicio de los derechos ARCO (acceso,
          rectificación, cancelación u oposición) mismo que puedes contactar en
          caso de cualquier duda o solicitud con respecto a tus datos personales
          a través del correo electrónico y teléfono antes indicados.
        </p>
        <p class="title">
          ¿QUÉ MEDIOS TIENES PARA LIMITAR EL USO Y DIVULGACIÓN DE DATOS
          PERSONALES?
        </p>
        <p>
          Parcecash ha creado un listado de exclusión para personas que no
          deseen recibir publicidad de Parcecash. Si recibes publicidad de
          Parcecash y no deseas seguir recibiéndola, podrás enviar un correo
          electrónico con tu nombre completo a servicio@parcecash.co,
          solicitando tu eliminación de la lista de distribución con base en la
          cual se lleva a cabo el envío de la información o publicidad. En el
          mismo correo electrónico podrás solicitar más datos acerca de este
          listado.
        </p>
        <p class="title">CONTACTOS</p>
        <p>Correo electrónico: servicio@parcecash.co</p>
        <p>
          Página web:
          <span style="color: #0000ff">https://www.parcecash.co/</span> –
          Sección “Contáctenos”.
        </p>
        <p>Teléfono de atención al cliente: +57 601 5086273</p>
        <p>
          Horario de Atención: de 8:00 a 17:00 horas de lunes a viernes; Sáb de
          8:00 a 14:00
        </p>
        <p class="title">CAMBIOS AL AVISO DE PRIVACIDAD</p>
        <p>
          El presente Aviso se encuentra publicado para su consulta, en la
          página de Internet de Parcecash (<span style="color: #0000ff"
            >https://www.parcecash.co</span
          >). De igual forma, las modificaciones que en cualquier momento se
          hagan al presente Aviso, serán publicadas en dicha dirección.
        </p>
        <p>
          Al hacer clic en el botón “ACEPTAR” al calce, el Titular otorga su
          consentimiento expreso en términos del presente Aviso y por ende,
          consiente que sus Datos Personales sean tratados por Parcecash
          conforme a lo previsto en el presente Aviso.
        </p>
      </div>
    </div>
    <footPage></footPage>
  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "../components/scss/Aviso.scss";

export default {
  name: "Aviso",
  components: { headNav, footPage },
  data() {
    return {};
  },
};
</script>

<style  scoped>
.content_title {
  font-size: 18px !important;
  width: 576px;
  font-weight: 600 !important;
}
.Aviso p {
  margin-bottom: 20px;
}
.p-bottom {
  margin-bottom: 0 !important;
}
.title {
  font-size: 23px !important;
  margin-top: 15px;
  font-weight: 600;
}
</style>